@import "../../styles/index.scss";

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60vh;
    input {
        padding: 8px;
        border-radius: 5px;
        text-transform: uppercase;
        outline: none;
        color: white;
        height: 5vh;
        font-size: 14px;
        padding-left: 15px;
        background: transparent;
        border: 2px solid $primaryColor;
    }
    
    textarea {
        padding: 8px;
        border-radius: 5px;
        border: 2px solid $primaryColor;
        outline: none;
        color: white;
        background: transparent;
        font-size: 17px;
        padding-left: 15px;
    }

    button {
        background: $primaryColor;
        border: none;
        color: $buttonText;
        padding: 13px;
        font-size: 18px;
        cursor: pointer;
    }
}

@media screen and (max-width: 600px){
    form{
        width: 100%;
    }
}
@import '../../styles/index.scss';

.skeleton-loader {
  width: 55vh;
  height: 50vh;
  background-color: #4048587d;
  background-size: 200%;
}

.LoadMore {
  position: relative;

  >div {
    height: 30vh;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      filter: blur(10px);
      object-fit: cover;
    }
  }

  >div:last-child {
    height: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    border: 2px solid yellowgreen;
    padding: 10px;
    box-shadow: 10px 10px $backgroundColor;
    h3{
      color: $backgroundColor;
    }
  }
}
@import '../../styles/index.scss';

.about {
    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            font-size: 22px;
        }
    }

    .skills {
        h2 {
            font-size: 2.1rem;
            margin-top: 2rem;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }

        .icons {
            font-size: 4rem;
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 600px) {

    .about {
        .info {
            padding: 10px;

            p {
                font-size: 13px;
            }
        }

        .skills {
            h2{
                font-size: 2rem;
                text-align: center;
            }
            .icons {
                padding: 10px;
                font-size: 2.7rem;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

}